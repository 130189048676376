<div class="auth-layout__container">
  <div class="left-dynamic__container w-full">
    <div class="h-full flex flex-col left-inner__container w-full">
      <div class="logo__container flex justify-start">
        <a href="#" class="cursor-pointer">
          <img
            class="w-auto mango-logo"
            src="assets/images/mango-logo--tinified.png"
            alt="Mango Logo"
          />
        </a>
      </div>
      <div
        class="dynamic-content__container flex justify-center items-center pb-8 w-full"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="right-static__container hidden">
    <div class="aside__cta">
      <div class="aside__content text-white flex flex-col">
        <h2 class="!mb-0 font-black">
          Build Wealth the <span class="text-mango">Smart Way</span>
        </h2>

        <p class="!mb-0">
          Save and invest with Mango Asset Management, the only wealth
          management app you really need.
        </p>
      </div>
    </div>
  </div>
</div>
